import React, { useCallback, useEffect, useState } from 'react'
import {
  Routes,
  Route,
  NotFoundRedirect,
} from '@databyss-org/ui/components/Navigation'
import { useSessionContext } from '@databyss-org/services/session/SessionProvider'
import { SearchProvider, UserPreferencesProvider } from '@databyss-org/ui/hooks'
import { ExportProvider } from '@databyss-org/services/export'
import {
  Sidebar,
  useNavigationContext,
  ModalManager,
  PageContent,
  Text,
} from '@databyss-org/ui'

import { GestureProvider, View } from '@databyss-org/ui/primitives'
import { BlockType } from '@databyss-org/services/interfaces'
import {
  SourcesContent,
  IndexPageContent,
  SearchContent,
} from '@databyss-org/ui/modules'
import { EditorPageProvider } from '@databyss-org/services'
import { useAppState } from '@databyss-org/desktop/src/hooks'
import {
  lightTheme,
  lightContentTheme,
  darkTheme,
  darkContentTheme,
  pxUnits,
} from '@databyss-org/ui/theming/theme'
import { sidebar } from '@databyss-org/ui/theming/components'
import { useDocument } from '@databyss-org/data/pouchdb/hooks/useDocument'
import { dbRef } from '@databyss-org/data/pouchdb/dbRef'
import {
  PublicGroupHeader,
  PublicGroupFooter,
} from '@databyss-org/ui/components'

const AppView = ({ children }) => {
  const [sidebarWidth, setSidebarWidth] = useState(null)
  const isDarkModeRes = useAppState('darkMode')
  const groupRes = useDocument(dbRef.groupId, {
    enabled: !!dbRef.groupId,
  })

  useEffect(() => {
    window.eapi.state.get('sidebarWidth').then((width) => {
      setSidebarWidth(width ?? sidebar.width)
    })
  }, [])

  const onSidebarResized = useCallback(
    (width) => {
      setSidebarWidth(width)
      window.eapi.state.set('sidebarWidth', width)
    },
    [window.eapi]
  )
  return (
    <>
      {groupRes.isSuccess && (
        <PublicGroupHeader
          group={groupRes.data}
          darkMode={isDarkModeRes.data}
          theme={isDarkModeRes.data ? darkTheme : lightContentTheme}
          setDarkMode={(value) => window.eapi.state.set('darkMode', value)}
        />
      )}
      <View
        flexDirection="row"
        display="flex"
        width="100%"
        overflow="hidden"
        flexShrink={1}
        flexGrow={1}
        bg="background.1"
        theme={isDarkModeRes.data ? darkContentTheme : lightContentTheme}
      >
        {sidebarWidth !== null && (
          <Sidebar
            onResized={onSidebarResized}
            width={sidebarWidth}
            theme={isDarkModeRes.data ? darkTheme : lightTheme}
          />
        )}
        <View
          data-test-element="body"
          flexGrow={1}
          flexShrink={1}
          overflow="hidden"
        >
          <View
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            bg="background.2"
            minHeight={pxUnits(58)}
            width="100%"
            position="absolute"
            pr="medium"
            pl={pxUnits(18)}
          >
            <View flexGrow={1}>
              <Text color="text.3" variant="uiTextSmall">
                Loading...
              </Text>
            </View>
          </View>
          <View flex="1" height="100%" position="relative">
            {children}
          </View>
        </View>
      </View>
      <PublicGroupFooter
        group={groupRes.data}
        theme={isDarkModeRes.data ? darkTheme : lightTheme}
      />
    </>
  )
}

const Providers = ({ children }) => (
  <UserPreferencesProvider>
    <ExportProvider>
      <SearchProvider>
        <GestureProvider>{children}</GestureProvider>
      </SearchProvider>
    </ExportProvider>
  </UserPreferencesProvider>
)

const Private = () => {
  const { location } = useNavigationContext()
  const getSession = useSessionContext((c) => c && c.getSession)
  const navigateToDefaultPage = useSessionContext(
    (c) => c && c.navigateToDefaultPage
  )
  const { provisionClientDatabase } = getSession()

  // Navigate to default page if nothing in path
  useEffect(() => {
    if (location.pathname === '/' || provisionClientDatabase) {
      navigateToDefaultPage(false)
    }
  }, [])

  return (
    <Providers>
      <AppView>
        <Routes>
          <Route path="/:accountId/*">
            <Route
              path="pages/:id/*"
              element={
                <EditorPageProvider>
                  <PageContent />
                </EditorPageProvider>
              }
            />
            <Route path="search/:query" element={<SearchContent />} />
            <Route
              path="sources/:blockId/*"
              element={<IndexPageContent blockType={BlockType.Source} />}
            />
            <Route
              path="topics/:blockId/*"
              element={<IndexPageContent blockType={BlockType.Topic} />}
            />
            <Route path="sources/*" element={<SourcesContent />} />
            <Route
              path="embeds/:blockId/*"
              element={<IndexPageContent blockType={BlockType.Embed} />}
            />
            <Route path="*" element={<NotFoundRedirect />} />
          </Route>
          <Route path="*" element={<NotFoundRedirect />} />
        </Routes>
        <ModalManager />
      </AppView>
    </Providers>
  )
}

export default Private
