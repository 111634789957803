import InfoModal from './InfoModal'
import MetadataModal from './MetadataModal'
import SourceModal from './SourceModal'
import TopicModal from './TopicModal'
import EmbedModal from './EmbedModal'
import { ImportDbModal } from './ImportDbModal'
import { ExportDbModal } from './ExportDbModal'

export default {
  INFO: InfoModal,
  METADATA: MetadataModal,
  SOURCE: SourceModal,
  TOPIC: TopicModal,
  EMBED: EmbedModal,
  IMPORTDB: ImportDbModal,
  EXPORTDB: ExportDbModal,
}
