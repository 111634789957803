import React, { useState, useEffect, useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from '@databyss-org/ui/components/Navigation/NavigationProvider'
import { useSessionContext } from '@databyss-org/services/session/SessionProvider'
import { EditorPageLoader } from '@databyss-org/ui/components/Loaders'
import { View } from '@databyss-org/ui/primitives'
import { useEditorPageContext } from '@databyss-org/services'
import { useNavigationContext } from '@databyss-org/ui'
import { getAuthToken } from '@databyss-org/services/session/clientStorage'
import { selectors } from '@databyss-org/data/pouchdb/selectors'
import { PageBody } from './PageBody'
import { FlatPageBody } from './FlatPageBody'
import PageSticky from './PageSticky'

// const INTERACTION_EVENTS = 'pointerdown keydown wheel touchstart focusin'

export const PageContainer = ({ page, isReadOnly, ...others }) => {
  const focusIndex = useEditorPageContext((c) => c.focusIndex)
  const [, setAuthToken] = useState()
  const [editorPath, setEditorPath] = useState(null)
  // const navigate = useNavigationContext((c) => c.navigate)
  // const pagesRes = usePages()
  // const path = getTokensFromPath()

  // confirms a token is in local pouch in order to show account menu
  useEffect(() => {
    const _token = getAuthToken()
    if (_token) {
      setAuthToken(true)
    }
  }, [])

  // const updateUrl = useCallback(
  //   debounce((url) => {
  //     navigate(url, { replace: true })
  //   }, 1000)
  // )

  // useEffect(() => {
  //   const niceName = urlSafeName(pagesRes.data?.[page._id]?.name)
  //   let redirectTo = location.pathname

  //   if (niceName) {
  //     if (!path.nice?.length) {
  //       redirectTo = `${location.pathname}/${niceName}${location.hash}`
  //     } else if (path.nice.join('/') !== niceName) {
  //       redirectTo = `/${path.type}/${path.params}/${niceName}${location.hash}`
  //     }
  //   }
  //   if (redirectTo !== location.pathname) {
  //     updateUrl(redirectTo)
  //   }
  // }, [pagesRes.data?.[page._id]?.name])

  // preload embed docs into cache
  const queryClient = useQueryClient()
  // const linkedDocIds = getLinkedDocIds(page)
  // const linkedDocsRes = useDocuments(linkedDocIds, {
  //   subscribe: false,
  // })

  // useEffect(() => {
  //   if (linkedDocsRes.isSuccess) {
  //     Object.values(linkedDocsRes.data).forEach((_doc) => {
  //       queryClient.setQueryData([`useDocument_${_doc._id}`], _doc)
  //     })
  //   }
  // }, [linkedDocsRes.isSuccess])

  useEffect(() => {
    queryClient.resetQueries({ queryKey: [selectors.BLOCKS], exact: true })
  }, [])

  return useMemo(
    () => (
      // linkedDocsRes.isSuccess ? (
      <>
        <PageSticky pagePath={editorPath} pageId={page._id} />
        <View flexShrink={1} flexGrow={1} overflow="hidden" {...others}>
          {isReadOnly ? (
            <FlatPageBody page={page} />
          ) : (
            <PageBody
              onEditorPathChange={setEditorPath}
              page={page}
              focusIndex={focusIndex}
            />
          )}
        </View>
      </>
      // ) : (
      // <LoadingFallback resource={linkedDocsRes} />
      // ),
    ),
    [
      // linkedDocsRes.isSuccess,
      isReadOnly,
      page._id,
      focusIndex,
      editorPath?.path?.join('/'),
    ]
  )
}

const PageContent = (others) => {
  // get page id and anchor from url
  const { id } = useParams()
  const getTokensFromPath = useNavigationContext((c) => c.getTokensFromPath)
  const { anchor } = getTokensFromPath()
  const isReadOnly = useSessionContext((c) => c && c.isReadOnly)

  /*
  use same route to update name, just pass it name 
  */

  return useMemo(
    () => (
      <View flex="1" height="100%">
        {id && (
          <EditorPageLoader pageId={id} firstBlockIsTitle>
            {(page) => (
              <PageContainer
                id={id}
                page={page}
                // anchor={anchor}
                isReadOnly={isReadOnly}
                {...others}
              />
            )}
          </EditorPageLoader>
        )}
      </View>
    ),
    [id, isReadOnly, anchor]
  )
}

// function getLinkedDocIds(page) {
//   const _docIds = {}
//   page.blocks.forEach((_block) => {
//     if (!_block?.text?.ranges) {
//       return
//     }
//     _block.text.ranges.forEach((_range) => {
//       _range.marks.forEach((_mark) => {
//         if (
//           Array.isArray(_mark) &&
//           _mark.length > 1 &&
//           _mark[1] &&
//           !_mark[1].match(validUriRegex)
//         ) {
//           _docIds[_mark[1]] = true
//         }
//       })
//     })
//   })
//   return Object.keys(_docIds)
// }

export default PageContent
